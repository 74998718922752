import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withTrans } from '../i18n/withTrans';
import Layout from '../components/Layout/Layout';
import Favicon from '../assets/images/favicon-manatour-16x16.png';

const MaintenancePage = ({ t }) => {
  return (
    <Layout pageName="reservation_page" withFooter={false}>
      <Helmet>
        <title>{t('helmet.reservation_page')}</title>
        <link
          rel="icon"
          type="image/png"
          href={Favicon}
          sizes="16x16"
          className="favicon"
        />
      </Helmet>
      <div
        style={{
          textAlign: 'center',
          marginTop: '50px'
        }}
      >
        <p
          style={{
            color: '#FFFFFF',
            fontSize: '52px',
            fontFamily: 'Roboto',
            textDecoration: 'underline'
          }}
        >
          {t('maintenance.title')}
        </p>
        <p style={{ color: '#FFFFFF', fontSize: '32px', fontFamily: 'Roboto' }}>
          {t('maintenance.content_1')}
        </p>
        <p style={{ color: '#FFFFFF', fontSize: '32px', fontFamily: 'Roboto' }}>
          {t('maintenance.content_2')}
        </p>
      </div>
    </Layout>
  );
};

export default withTrans(MaintenancePage);

MaintenancePage.propTypes = {
  t: PropTypes.func.isRequired
};
